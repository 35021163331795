import React, { useEffect, useState } from 'react';
import AddUserModal from './AddUserModal';
import axios from '../../custom-axios';
import { NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';

const headers = [
    { label: 'Username', key: 'username' },
    { label: 'Country', key: 'country' },
    { label: 'Site', key: 'site' }
];

const INITIAL_STATE = {
    username: '',
    country: '---',
    site: ''
};

const Users = () => {
    const [users, setUsers] = useState([]);
    const [state, setState] = useState(INITIAL_STATE);

    const fetchUsers = () => {
        axios({
            url: '/users',
            method: 'GET'
        })
            .then(response => {
                filterData(response.data.users);
            })
            .catch(error => {
                alert('Fetching users error! Please try again! Something went wrong.');
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const onChange = e => {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const filterData = users => {
        const newUsers = users.filter(user => {
            const conditions = [];
            if (state.username !== '') {
                conditions.push(user.username.toLowerCase().includes(state.username));
            }

            if (state.country !== '---') {
                conditions.push(user.country === state.country);
            }

            if (state.site !== '') {
                conditions.push(user.site.toLowerCase().includes(state.site));
            }

            return conditions.length === 0 || conditions.every(value => value === true);
        });

        setUsers(newUsers);
    };

    const reset = () => {
        fetchUsers();
        setState(INITIAL_STATE);
    };

    return (
        <div className='container'>
            <div className='d-flex align-items-center justify-content-between text-start'>
                <div className='d-flex'>
                    <div className='mb-3'>
                        <label htmlFor='username' className='form-label'>
                            Search by username
                        </label>
                        <input
                            type='text'
                            onChange={onChange}
                            value={state.username}
                            name='username'
                            className='form-control'
                            id='exampleInputEmail1'
                            aria-describedby='emailHelp'
                        />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='mb-3'>
                        <label htmlFor='country' className='form-label'>
                            Country
                        </label>
                        <select
                            className='form-select form-control-lg'
                            aria-label='Default select example'
                            onChange={onChange}
                            value={state.country}
                            name='country'
                        >
                            <option>---</option>
                            <option value='South Africa'>South Africa</option>
                            <option value='Ethiopia'>Ethiopia</option>
                            <option value='Tanzania'>Tanzania</option>
                        </select>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='mb-3'>
                        <label htmlFor='site' className='form-label'>
                            Site
                        </label>
                        <input
                            type='text'
                            value={state.site}
                            onChange={onChange}
                            name='site'
                            className='form-control'
                            id='site'
                        />
                    </div>
                </div>
                <div className='d-flex table-actions'>
                    <button className='btn btn-outline-primary me-5' onClick={fetchUsers}>
                        Filter
                    </button>
                    <button className='btn btn-outline-warning me-5' onClick={reset}>
                        Reset filters
                    </button>
                    <CSVLink data={users} headers={headers}>
                        Download
                    </CSVLink>
                </div>
            </div>
            <div className='d-flex flex-row-reverse'>
                <button
                    className='btn btn-outline-success btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#modal'
                >
                    Add user
                </button>
            </div>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th scope='col'>ID</th>
                            <th scope='col'>Username</th>
                            <th scope='col'>Country</th>
                            <th scope='col'>Site</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users &&
                            users?.map(user => (
                                <tr key={user.id}>
                                    <td
                                        scope='col'
                                        onClick={() => {
                                            console.log('asdsa');
                                        }}
                                    >
                                        <NavLink to={`users/${user.id}`}>{user.id}</NavLink>
                                    </td>
                                    <td scope='col'>{user.username}</td>
                                    <td scope='col'>{user.country}</td>
                                    <td scope='col'>{user.site}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <AddUserModal fetchUsers={fetchUsers} />
        </div>
    );
};
export default React.memo(Users);
