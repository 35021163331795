import React, { useState } from 'react';
import axios from '../../custom-axios';

const INITIAL_STATE = {
    username: '',
    password: '',
    country: '---',
    site: '',
    capacity: '',
    prefix: '',
    start: ''
};
const AddUserModal = ({ fetchUsers }) => {
    const [state, setState] = useState(INITIAL_STATE);

    const closeModal = () => {
        document.getElementById('close').click();
    };

    const onChange = e => {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const addUser = () => {
        let isValid = true;
        Object.keys(state).forEach(key => {
            if (state[key] === null || state[key] === '' || state[key] === '---') {
                isValid = false;
            }
        });

        if (+state.capacity % 2 !== 0) {
            alert('Number of participants must be even!');
            return;
        }

        if (+state.capacity < 10) {
            alert('Number of participants must be greater than 10!');
            return;
        }

        if (isValid) {
            axios({
                url: '/users',
                method: 'POST',
                data: state
            })
                .then(response => {
                    setState(INITIAL_STATE);
                    fetchUsers();
                    closeModal();
                })
                .catch(error => {
                    const errorMessage = JSON.stringify(error?.response?.data?.reason);
                    if (errorMessage) {
                        alert(JSON.stringify(error?.response?.data?.reason));
                    }
                });
        } else {
            alert('All fields are mandatory!');
        }
    };
    return (
        <div className='modal' tabIndex='-1' id='modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Add new users</h5>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => {
                                setState(INITIAL_STATE);
                            }}
                        />
                    </div>
                    <div className='modal-body text-start'>
                        <div className='row'>
                            <div className='mb-3'>
                                <label htmlFor='username' className='form-label'>
                                    Email address
                                </label>
                                <input
                                    type='text'
                                    onChange={onChange}
                                    value={state.username}
                                    name='username'
                                    className='form-control'
                                    id='exampleInputEmail1'
                                    aria-describedby='emailHelp'
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='password' className='form-label'>
                                    Password
                                </label>
                                <input
                                    type='password'
                                    onChange={onChange}
                                    value={state.password}
                                    name='password'
                                    className='form-control'
                                    id='password'
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='country' className='form-label'>
                                    Country
                                </label>
                                <select
                                    className='form-select'
                                    aria-label='Default select example'
                                    onChange={onChange}
                                    value={state.country}
                                    name='country'
                                >
                                    <option>---</option>
                                    <option value='South Africa'>South Africa</option>
                                    <option value='Ethiopia'>Ethiopia</option>
                                    <option value='Tanzania'>Tanzania</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='site' className='form-label'>
                                    Site
                                </label>
                                <input
                                    type='text'
                                    value={state.site}
                                    onChange={onChange}
                                    name='site'
                                    className='form-control'
                                    id='site'
                                />
                            </div>
                            <div className='mb-3'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label htmlFor='prefix' className='form-label'>
                                            Prefix
                                        </label>
                                        <input
                                            type='text'
                                            value={state.prefix}
                                            onChange={onChange}
                                            name='prefix'
                                            className='form-control'
                                            id='prefix'
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <label htmlFor='start' className='form-label'>
                                            Start
                                        </label>
                                        <input
                                            type='number'
                                            step={1}
                                            min={1}
                                            value={state.start}
                                            onChange={onChange}
                                            name='start'
                                            className='form-control'
                                            id='start'
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <label htmlFor='capacity' className='form-label'>
                                            Capacity
                                        </label>
                                        <input
                                            type='number'
                                            step={1}
                                            min={5}
                                            value={state.capacity}
                                            onChange={onChange}
                                            name='capacity'
                                            className='form-control'
                                            id='capacity'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            id='close'
                            className='btn btn-secondary'
                            data-bs-dismiss='modal'
                            onClick={() => {
                                setState(INITIAL_STATE);
                            }}
                        >
                            Close
                        </button>
                        <button type='button' className='btn btn-primary' onClick={addUser}>
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddUserModal;
