class AuthUtils {
    static TOKEN = 'jwtToken';

    static USERNAME = 'username';

    static getToken() {
        return localStorage.getItem(this.TOKEN);
    }

    static storeToken(token) {
        localStorage.setItem(this.TOKEN, token);
    }

    static removeCredentials() {
        localStorage.removeItem(this.TOKEN);
    }

    static getUsername() {
        return localStorage.getItem(this.USERNAME);
    }

    static storeUsername(username) {
        localStorage.setItem(this.USERNAME, username);
    }
}

export default AuthUtils;
