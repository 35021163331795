import './App.css';
import SignIn from './components/SignIn/SignIn';
import Navigation from './components/Navigation/Navigation';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from './custom-axios';
import AuthUtils from './utils/AuthUtils';
import Home from './components/Home/Home';
import Users from './components/Users/Users';
import Randomization from './components/Randomization/Randomization';
import { history } from './utils/history';

function App() {
    console.log(process.env);
    const [state, setState] = useState({
        username: AuthUtils.getUsername()
    });

    const setUsername = username => {
        setState(prevState => {
            return {
                ...prevState,
                username: username
            };
        });
    };

    useEffect(() => {
        const token = AuthUtils.getToken();
        const username = AuthUtils.getUsername();
        if (token) {
            setState(prevState => {
                return {
                    ...prevState,
                    username: username
                };
            });
        }
    }, [state.username]);

    const signIn = (username, password) => {
        if (username && password) {
            axios({
                url: '/login/admin',
                method: 'POST',
                data: {
                    username: username,
                    password: password
                }
            })
                .then(response => {
                    AuthUtils.storeToken(response.data.token);
                    AuthUtils.storeUsername(username);
                    setState(prevState => {
                        return {
                            ...prevState,
                            username: username
                        };
                    });
                    history.replace('/');
                })
                .catch(error => {
                    alert('Please try again! Something went wrong.');
                });
        }
    };
    let routes = (
        <>
            <Switch>
                <Route path='/sign-in' render={() => <SignIn signIn={signIn} />} exact={true} />
                <Redirect to='/sign-in' />
            </Switch>
        </>
    );

    if (state.username) {
        routes = (
            <Switch>
                <Route path='/' component={Home} exact={true} />
                <Route path='/users' component={Users} exact={true} />
                <Route path='/users/:id' component={Randomization} />
                <Route path='/sign-in' render={() => <SignIn signIn={signIn} />} exact={true} />
                <Redirect to='/' />
            </Switch>
        );
    }
    return (
        <div className='App'>
            <div className='container-fluid'>
                <Navigation username={state.username} setUsername={setUsername} />
                <main>{routes}</main>
            </div>
        </div>
    );
}
export default React.memo(App);
