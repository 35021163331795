import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../custom-axios';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const headers = [
    { label: 'Arm', key: 'arm' },
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Redcap participant ID', key: 'pid' }
];

const Randomization = () => {
    const location = useLocation();
    const [state, setState] = useState({ data: {} });

    const fetchRandomization = userId => {
        axios({
            url: `/data/${userId}`,
            method: 'GET'
        })
            .then(response => {
                setState(prevState => {
                    return {
                        ...prevState,
                        data: response.data
                    };
                });
            })
            .catch(error => {
                alert('Fetching users error! Please try again! Something went wrong.');
            });
    };

    useEffect(() => {
        const id = location.pathname.split('/')[2];
        fetchRandomization(id);
    }, [location]);

    return (
        <div className='container'>
            <div className='d-flex align-items-start mb-2 font-monospace'>
                <div className='d-flex align-items-center me-4'>
                    Assigned participants:
                    <span className='badge bg-primary d-flex ms-1 px-2'>{state.data.assigned}</span>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    Last updated:
                    <span className='badge bg-success ms-1 px-2'>
                        {moment(new Date(state.data.lastUpdated)).format('YYYY-MM-DD HH:mm').toString()}
                    </span>
                </div>
                <div className='table-actions d-flex align-items-center flex-grow-1 justify-content-end'>
                    {state.data?.arms && (
                        <CSVLink data={state.data.arms.filter(arm => arm.timestamp)} headers={headers} filename={`randomizations.csv`}>
                            Download
                        </CSVLink>
                    )}
                </div>
            </div>
            <div className='table-responsive table-responsive-sm'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th scope='col'>ID</th>
                            <th scope='col'>Arm</th>
                            <th scope='col'>Timestamp</th>
                            <th scope='col'>Redcap participant ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.data?.arms?.map(sample => (
                            <tr>
                                <th>{sample.id}</th>
                                <th>
                                    <span className='w-150px text-start'>{sample.arm}</span>
                                </th>
                                <th>
                                    {sample.timestamp
                                        ? moment(new Date(sample.timestamp))
                                              .format('YYYY-MM-DD HH:mm')
                                              .toString()
                                        : null}
                                </th>
                                <th>{sample.pid}</th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default React.memo(Randomization);
