import React, { useState } from 'react';
import './SignIn.css';
import logo from '../../sstb-icon.png';

const SignIn = ({ signIn }) => {
    const [state, setState] = useState({ username: '', password: '' });
    const onChange = e => {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    return (
        <main className='form-signin'>
            <form>
                <img className='mb-4' src={logo} alt='Logo' width='75' height='65' />
                <h1 className='h3 mb-3 fw-normal'>Please sign in</h1>
                <div className='form-floating'>
                    <input
                        type='email'
                        onChange={onChange}
                        className='form-control'
                        placeholder='Your e-mail'
                        name='username'
                    />
                    <label htmlFor='floatingInput'>Email address</label>
                </div>
                <div className='form-floating'>
                    <input
                        type='password'
                        onChange={onChange}
                        className='form-control'
                        placeholder='Your password'
                        name='password'
                    />
                    <label htmlFor='floatingPassword'>Password</label>
                </div>
                <button
                    className='w-100 btn btn-lg btn-primary'
                    type='button'
                    onClick={() => {
                        signIn(state.username, state.password);
                    }}
                >
                    Sign in
                </button>
                <p className='mt-5 mb-3 text-muted'>© 2021</p>
            </form>
        </main>
    );
};
export default SignIn;
