import React from 'react';
import './Navigation.css';
import { history } from '../../utils/history';
import { NavLink } from 'react-router-dom';
import logo from '../../SeroSelectTB-Logo-Transparent-500px.png';
import AuthUtils from '../../utils/AuthUtils';

const Navigation = ({ username, setUsername }) => {
    const logout = () => {
        AuthUtils.removeCredentials();
        setUsername('');
    };

    return (
        <nav className='navbar navbar-expand-md navbar-light bg-light border-bottom'>
            <div className='container-fluid'>
                <img
                    src={logo}
                    alt='Logo'
                    className='cursor-pointer'
                    width={200}
                    height={30}
                    onClick={() => {
                        history.replace('/');
                    }}
                />
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarsExample04'
                    aria-controls='navbarsExample04'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon' />
                </button>

                <div className='collapse navbar-collapse' id='navbarsExample04'>
                    <ul className='navbar-nav me-auto mb-2 mb-md-0'>
                        {username && (
                            <>
                                <li className='nav-item'>
                                    <NavLink className='nav-link' to={'/users'} strict={true}>
                                        Users
                                    </NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                <div className='d-flex align-items-end justify-content-center'>
                    {username ? (
                        <div>
                            <span className='text text-black-70 p-2'>Welcome, {username}!</span>
                            <button className='btn btn-outline-danger' onClick={logout}>
                                Logout
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </nav>
    );
};
export default Navigation;
